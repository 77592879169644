import { useUserStore } from '~/stores/user'
import type { IUser } from '~/types/models/user.interface'

export default defineNuxtRouteMiddleware(async () => {
	const store = useUserStore()
	const user = await useApi('/auth/me')

	if (user) {
		store.login(user as IUser)
		return navigateTo('/')
	}

	store.logout()
})
